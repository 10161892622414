import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2.js";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import { errorContext } from "context/errorContext";
import { UseTranslation, useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { apkServices } from "services/apkServices.js";
import CommonForm from "components/common/form/common-form";
import PathLink from "@material-ui/icons/Link";
import { Icon } from "@material-ui/core";

const ApkList = props => {

  const { t } = useTranslation();

  const [status, setStatus] = React.useState("");
  const [pageInfo, setPageInfo] = React.useState({});  
  const {dispatch: errorDispatch } = useContext(errorContext);
  const [keyValue, setKeyValue] = useState([]);
  const [appTypeList, setAppTypeList] = React.useState([]);

  // const appType = [
  //   {name : "smartfarms", value: "smartfarms"},
  //   {name : "mooretail", value: "mooretail"}];

  const ReleaseType = [
    { name: "GENERIC", value: "GENERIC" },
    { name: "ALPHA", value: "ALPHA" }];

  useEffect(() => {
      apkServices.getApkPackages().then(data => {
        if (data ) { 
        console.log("data",data)
        setAppTypeList(data);  
        getAPKUploadList(values);        
        }        
      })
    },[]); 

  let values = {    
    appType: "",
    releaseType: "",
    versionName: ""
  }

   const fields = [
      // {
      //   type: "select",
      //   name: "appType",
      //   label: t("apk_List_appType_Label"),
      //   placeholder: t("apk_List_appType_Label"),
      //   option: appType.length > 0
      //     ? appType.map(apptype => ({
      //       value: apptype.value,
      //       label: apptype.name
      //     }))
      //     : [],
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   },        
      // }, 
      
      {
        type: "select",
        name: "appType",
        label: t("apk_Form_appType_Label"),
        placeholder: t("apk_Form_appType_Label"),
        option:
        appTypeList.length > 0
            ? appTypeList.map(aType => ({
                value: aType.appType,
                label: aType.appType
              }))
            : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        },            
      }, 
        
      {
        type: "select",
        name: "releaseType",      
        label: t("apk_List_releaseType_Label"),
        placeholder: t("apk_List_releaseType_Label"),
        option: 
        ReleaseType.length > 0
          ? ReleaseType.map((rType) => ({
              value: rType.value,  // Use 'value' from the userType array
              label: rType.name    // Use 'name' from the userType array
            }))
          : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },

      {
        type: "text",         //  singleSelectSearch
        name: "versionName",      
        label: t("apk_List_versionName_Label"),
        placeholder: t("apk_List_versionName_Label"),
        autoComplete: "off",   // Disable autocomplete
        // option:
        // articleLangSC.length > 0
        //     ? articleLangSC.map(articleLangSC => ({
        //         value: articleLangSC.id,
        //         label: articleLangSC.name
        //       }))
        //     : [],
        grid: {
          xs: 12,
          sm: 3,
          //md: 4,
          className: "mb-2"
        },        
      },
    ];    

  function handleSubmit(values, resetForm, setSubmitting) {
    getAPKUploadList(values);
    setSubmitting(false);
  }

  function getAPKUploadList(values) {
    setStatus("loading"); 
    apkServices.getApkUploadList(values.appType, values.releaseType, values.versionName).then((data) => {
      console.log("apkUploadList", data);
      setKeyValue(data || []);
      setPageInfo(data.pageInfo);      
      setStatus("success");     
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  const formikForm = React.useRef(null);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: t("Created Date"),
      //   accessor: "createdDate",
      // },
      {
        Header: t("Created By"),
        accessor: "createdBy",
      }, 
      {
        Header: t("App Type"),
        accessor: "appType",
      },   
      {
        Header: t("Version Name"),
        accessor: "versionName",
      },   
      {
        Header: t("Version Code"),
        accessor: "versionCode",
      },   
      {
        Header: t("Release Type"),
        accessor: "releaseType",
      },   
      {
        Header: t("Uploaded File Name"),
        accessor: "uploadedFileName",
      },       
      {
        Header: t("Download Link"),
        accessor: "downloadLink",
        Cell: ({ cell: { value } }) => (             
          <a href={value} target="_blank" rel="noopener noreferrer">
            {/* <img src={value} alt=" No Image" width="50" height="50"/> */}
            <Icon component={PathLink} style={{ fontSize: 30 }} />
          </a>    
        ),
      } 
    ],
    []
  );

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      //getAPKUploadList();      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  const data = React.useMemo(() => [...keyValue]);

    const dropitems = [
      {
        item: (
          <Link to="/apk/apk_upload">
           {t("apk_upload_Label")}
          </Link>
        )
      }
    ];  

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Apk Upload List"
          // breadcrumbs={[
          //   { label: "Dashboard", link: "/dashboard" },
          //   { label: "Configuration" },
          //   { label: "Article List" }
          // ]}
        />
      </Grid>     
      
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >        
            <CommonForm
              fields={fields}
              submitLabel={t("articleList_Form_Submit_Label")}
              submittingLabel={t("articleList_Form_Submitting_Label")}
              initialValues={values}
              //validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
                }}
              formikRef={formikForm}
              buttonSize="2"
              buttonPosition="right"
              inlineButton={true}              
            />         

        {status === "loading" ? (
          <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
         <CircularProgress />
        </Grid>
          
        ) : (
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}          
          />
        )}
        
      </Paper>      

    </Grid>
  );

};

export default ApkList;
