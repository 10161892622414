import React, { useState, useContext, useEffect, useCallback } from "react";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import { useTranslation } from "react-i18next";

export default function ApkBlankForm({ 
page
}) {
  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);  
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Apk"          
        />       
      </Grid>

      <Paper
        // elevation={2}
        // style={{
        //   padding: "2em",
        //   width: "100%"
        // }}
        
      >
        <Grid item>
          {loadedPage == "add" &&
            <CommonForm /> 
          }
        </Grid>
      </Paper>      
    </Grid>
  );
}
