import React, { useEffect, useState, useContext } from "react";
import { provisioningService } from "services/provisioningServices";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams, useHistory } from "react-router-dom";
import ApkForm from "./ApkForm";
import { apkServices } from "services/apkServices";
import AlphaForm from "./AlphaForm";

export default function CreateAlpha() {
  const { t } = useTranslation();
  const history = useHistory();  
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");
  const [urlError, setUrlError] = useState("");

  useEffect(() => {
    provisioningService
      .verifyOtpNew("1313131313")
      .then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem(
          "bearerToken"
        );
        const organizationId = JSON.parse(localStorage.getItem("orgId"));       
      })
      .catch((error) => {
        errorDispatch({
          type: "ERROR",
          error,
        });
      });
  }, []);  

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  };

  let values = {
    userName: "",        
    phoneNum: "",
    userType: "ALPHA",
    appType: "smartfarms"    
  };

  async function handleSubmit(values, resetForm, setSubmitting) {
    console.log("alpha values", values);
    try {
      const repeat = null;
      const result = await apkServices.AlphaUserCreate(values);      
      console.log("Result:============= ", result);
      if (
        result.data === null ||
        result.message ||
        result.success === "false"
      ) {

        console.log("If Condition:==========",result)
        //setCreateFailure(true);
        //setFailureMsg(result.message);
        setSubmitting(false);
        setCreateSuccess(false);
        setCreateFailure(true);
        //setFailureMsg(result);
      } else {        
        try {
          const repeat = null;
          console.log("If else",result)
          resetForm();
          setCreateSuccess(true);
          setSubmitting(false);
          setTimeout(() => {
            history.push("/apk/alpha_list");
          }, 3000);
        } catch (e) {
          console.error(e);
          // setCreateFailure(true);
          // setFailureMsg(e.error.message);
          setSubmitting(false);
        }
      }
    } catch (e) {
      console.error(e);      
    }    
  }

  return (
      <>
        <grid>
          <AlphaForm
            page="add"
            values={values}            
            handleSubmit={handleSubmit}            
          />
        </grid>
  
        {createSuccess ? (
          <SweetAlert
            success
            title="Created Successfully"
            onConfirm={hideAlert}
            //disabled={ disableOkBtn ? true : false }
            onCancel={hideAlert}
            timeout={2000}
          ></SweetAlert>
        ) : null}
  
        {createFailure ? (
          <SweetAlert
            danger
            //title={failureMsg}
            //title="Duplicate record found of Version Code or Release Type"
            onConfirm={hideAlert}
            //disabled={ disableOkBtn ? true : false }
            onCancel={hideAlert}
            //timeout={3000}
          ></SweetAlert>
        ) : null}
      </>
    );
}
