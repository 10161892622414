import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
//import PbpForm from "./pbpForm";
import SweetAlert from 'react-bootstrap-sweetalert';
//import { productService } from "services/productServices";
//import { pbpService } from "services/pbpServices";
import { appConfigService } from "services/appConfigService.js";

import { errorContext } from "context/errorContext";
import { provisioningService } from "../../../services/provisioningServices.js";
import AppConfigForm from "./AppConfigForm.js";


export default function CreateAppConfig() {
  const organizationName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const organizationId = JSON.parse(localStorage.getItem("orgId"));
  const history = useHistory();
  //const [organization, setOrganization] = React.useState([]);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");
  const { dispatch: errorDispatch } = useContext(errorContext);    
  
  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 

  // to initialize the values when create template form loads first time
  let values = {

    templateName: "",
    appConfigDtoList:[
      {
        key: "",
        //value: ""
      }
    ],   
  };

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {
    console.log(values);
    
    let payLoad = {"templateName":values.templateName, "appConfigDtoList":values.keyValue}    
    console.log("payLoad", payLoad)

    try {
      const repeat = null;
      const result = await appConfigService.AppConfigCreate(payLoad);
      console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){        
        setCreateFailure(true);
        setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          resetForm();
          setCreateSuccess(true);
          //resetForm();      
          //redirect to list page
          setTimeout(() => {
           //history.push("/configuration/pbp_list");
            history.push("/configuration/appConfig_list");
          }, 3000); 
          
        } catch (e) {
          //console.error(e);
          setCreateFailure(true);
          setFailureMsg(e.error.message);
          setSubmitting(false);
        }

      }
    } catch (e) {
      console.error(e);
    }
    
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
      <AppConfigForm 
        page="add"
        values={values}               
        handleSubmit={handleSubmit}          
      />

      {createSuccess ? 
        <SweetAlert success
          title="Device Configuration Template Created Successfully"
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          timeout={2000}
        ></SweetAlert> 
      : null}

      {createFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}
      
    </>
  );
}
