import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";


const BrowseButtonApkFile = ({ setFieldValue,values}) => {
let responseImagePath;
  
const [error, setError] = useState('');
const [BrowseBtn, setBrowseBtn] = React.useState(""); 
const [BrowseBtnImport, setBrowseBtnImport] = React.useState("");
const [loadingImage, setLoadingImage] = useState("");  
//const [fileName, setFileName] = useState("");

const { t } = useTranslation();

const apkFile = (event) => {
    setError('');
    setLoadingImage('');
    
    const file = event.target.files[0];
    const fileName = event.target.files[0];

    if (!file) return; // Ensure there's a file selected
         
    setFieldValue("file",file);  
    setFieldValue("fileName",fileName.name)  
};  
  console.log('valuesimg',values);

  const ColorButton = withStyles(theme => ({
    root: {
      backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
      width: "100%",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
      }
    }
  }))(Button);
 
  return (
    <div>
      <Grid item container justify="space-between" alignItems="center"
        style={{ padding: "3em" , marginLeft: '-20%', marginTop: '-9.5%'}}>    
        <Grid item container md={4} alignItems="center">
        </Grid>
      
        <Grid item md={4} xs={12}>
          {BrowseBtn ?
            <div>
                <header style={{ fontSize: "12px", color: "#166aab" }}>{responseImagePath}</header>
                <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "50%", marginRight: "3px" }}>
                </div>
                <div style={{ width: "50%", marginLeft: "3px" }}>
                </div>
              </div>
            </div> :
            // <Tooltip title="Image Size should be less than 10 MB" placement="top">
              <ColorButton variant="contained"
                color="primary"
                component="label"
                className="align-self-end w-50 text-capitalize">                  
                Browse File
                <Input type="file" onChange={apkFile}
                  style={{ display: "none" }}
                  
                  //inputProps={{ accept: ".xlsx, .xls, .csv"}}
                  inputProps={{ accept: ".apk, .jpg, .txt" }}                 
                  //name="BrowseBtnImport" value={BrowseBtnImport} 
                  />                   
              </ColorButton>
            //</Tooltip>
          }
          {/* {responseImagePath == undefined && urlname != ""?
          <ColorButton variant="contained"
          color="primary"
          component="label"
          className="align-self-end w-50 text-capitalize">
          Browse File
          <Input type="file" onChange={apkFile}
            style={{ display: "none" }}
            //inputProps={{ accept: ".xlsx, .xls, .csv"}}
            inputProps={{ accept: ".txt, .pdf" }}
            name="@BrowseBtnImport" value={BrowseBtnImport}    />
        </ColorButton>
        :null
          } */}
          {/* {error && <p style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "-10px", marginTop: "10px" }}>
                    {error}</p>}         
          {loadingImage && <p style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "-10px", marginTop: "20px" }}>         {loadingImage}</p>} */}
        </Grid>
      </Grid> 
    </div>
    
    );

  //}

}

export default BrowseButtonApkFile;
