
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import * as Yup from "yup";
import BrowseButtonApkFile from "components/BrowseButtonApkFile";
import { apkServices } from "services/apkServices";

export default function ApkForm({  
values,
page,
handleSubmit

}) {

  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);  
  const [toggle,setToggle] = React.useState(false);
  //const [app_type, setApp_type] = React.useState(""); 
  const [app_type, setApp_type] = useState("");
  const [package_name, setPackage_name] = React.useState("");
  const [appTypeList, setAppTypeList] = React.useState([]);
  const [packageNameList, setPackageNameList] = React.useState([]);
  const [packageNameValue, setPackageNameValue] = React.useState([]);
  // const [variable,setvariable] = React.useState({...values})

  // const handleToggle = (event) => {
  //   setToggle(!toggle)
  //   values[event.target.name] = !values[event.target.name]
  //   console.log("Toggles: ", values[event.target.name]);
  //   console.log("Toggles: ", event.target.name);
  // }  

const ReleaseType = [
  { name: "GENERIC", value: "GENERIC" },
  { name: "ALPHA", value: "ALPHA" }];

// const ApkPackage = [
//   { name: "stellapps.farmerapp", value: "stellapps.farmerapp" },
//   { name: "stellapps.farmerapp.dev", value: "stellapps.farmerapp.dev" }];

useEffect(() => {
  apkServices.getApkPackages().then(data => {
    if (data ) { 
    console.log("data",data)
    setAppTypeList(data);          
    }        
  })
},[]); 

console.log("values in apkForm",values);

const selectAppType = (apptype) => {
  // console.log("variable",variable)
  // setvariable((prevState) => ({   
  //   ...prevState,    
  //   appType: apptype, // Update appType    
  // }));
  
  apkServices.getApkPackageName(apptype).then(data => {
    if (data ) {       
    setPackageNameList(data);       
    setPackageNameValue(data[0].packageName);
    // setvariable((prevState) => ({
    //   ...prevState,      
    //   apkPackage: data[0].packageName, // Update apkPackage based on appType
    // }));       
         
    }       
  })     
};

console.log('packageNameValue', packageNameValue); 

let validations = {
  fileName: Yup.string().required(t("apk_Form_Validation_fileName")),  
  appType: Yup.string().required(t("apk_Form_Validation_appType")),
  versionName: Yup.string().required(t("apk_Form_Validation_versionName")),   
  //apkPackage: Yup.string().required(t("apk_Form_Validation_apkPackage")),
  versionCode: Yup.string().required(t("apk_Form_Validation_versionCode"))                        
      .matches(/^[0-9]+$/, "Must be only digits"),  
  releaseType: Yup.string().required(t("apk_Form_Validation_releaseType"))    
  };  

  const fields = [ 
    {
      type: "selectMandatory",
      name: "appType",
      label: t("apk_Form_appType_Label"),
      placeholder: t("apk_Form_appType_Label"),
      //placeholder: app_type,
      //disabled: app_type,      
      option:
      appTypeList.length > 0
          ? appTypeList.map(aType => ({
              value: aType.appType,
              label: aType.appType
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange: selectAppType    
    },     

    // {
    //   type: "text",
    //   name: "apkPackage",      
    //   label: t("apk_Form_apkPackage_Label"),
    //   //placeholder: packageNameList.length > 0 ? packageNameList[0].packageName : '', 
    //   //placeholder:packageNameValue,
    //   //value: packageNameValue,
    //   //disabled: packageNameValue,      
    //   option: packageNameList.length > 0
    //       ? packageNameList.map(packName => ({
    //           value: packName.packageName,
    //           label: packName.packageName,
    //           value: packageNameValue
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   },
    // },

    {
      type: "fixedText",
      name: "apkPackage",      
      label: t("apk_Form_apkPackage_Label"),
      valuee: packageNameValue,
      // disabled: packageNameList,
      // option: packageNameList.length > 0
      //     ? packageNameList.map(packName => ({
      //         value: packName.packageName,
      //         label: packName.packageName,
      //       }))
      //     : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
    },
      
    {
      type: "selectMandatory",
      name: "releaseType",      
      label: t("apk_Form_releaseType_Label"),
      placeholder: t("apk_Form_releaseType_Label"),
      option: 
      ReleaseType.length > 0
        ? ReleaseType.map((rType) => ({
            value: rType.value,  // Use 'value' from the userType array
            label: rType.name    // Use 'name' from the userType array
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },    
    {},
    {
      type: "textMandatory",
      name: "versionCode",
      label: t("apk_form_versionCode_label"),
      placeholder: t("apk_form_versionCode_label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },

    {
      type: "textMandatory",
      name: "versionName",
      label: t("apk_form_versionName_label"),
      placeholder: t("apk_form_versionName_label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {},
    {
      type: "textMandatory",
      name: "file",
      name: "fileName",
      label: t("apk_form_file_label"),
      placeholder: t("apk_form_file_label"),
      grid: {
        xs: 12,
        sm: 5,
        className: ""
      }
    },
 
    {
      type: "custom",
      component: ({ values, setFieldValue}, renderFields) => (
        <BrowseButtonApkFile
          //handleMyClick={handleMyClick}
          //languageValues={language}
          setFieldValue={setFieldValue}
          values={values}              
          renderFields={renderFields}          
        />
      )
    }    

  ];

  console.log("values####",values);
  
  return (
  <Grid container>
    <Grid item xs={12}>
      <PageHeader
        title="Apk Upload"
        // breadcrumbs={[
        //   { label: "Dashboard", link: "/dashboard" },
        //   { label: "Configuration" },
        //   { label: "Article" }
        // ]}
      />
    </Grid>

    <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      > 

      <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}              
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}              
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}                           
              enableReinitialize
              validateOnChange={true}
              onSubmit={(values, { resetForm, setSubmitting }) => {              
              handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}             
              buttonSize="3"
              buttonPosition="right"                       
            />            
          }
        </Grid>
        
     </Paper>
  </Grid> // Grid container closed here
  );
}
