import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2.js";
//import Table from "components/common/table/ControlledFullPaginationTable";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import { errorContext } from "context/errorContext";
import { UseTranslation, useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { apkServices } from "services/apkServices.js";
import IconButton from "@material-ui/core/IconButton";
import CommonForm from "components/common/form/common-form";
import { useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import TextField from "@material-ui/core/TextField";

const AlphaList = props => {

  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const history = useHistory();

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});  
  const {dispatch: errorDispatch } = useContext(errorContext);
  const [keyValue, setKeyValue] = useState([]);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");  

  const [selectedObjId, setSelectedObjId] = useState(""); 
  const [alphaUserName, setAlphaUserName] = useState(""); 
  const [alphaPhoneNum, setAlphaPhoneNum] = useState(""); 
  const [alphaUserType, setAlphaUserType] = useState(""); 
  const [alphaAppType, setAlphaAppType] = useState(""); 

  const [editingRowId, setEditingRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [focusedCell, setFocusedCell] = useState(null);  
  const [passbookData, setPassbookData] = useState([]);
  const [reloaddd, setReloaddd] = useState(false);  

  const handleEditClick = (row) => {
    setEditingRowId(row.original.id);
    setEditedData({ ...row.original });  
    setFocusedCell(row.original.id); 
    // setFocusedCell(`${row.original.id}-userName`);
  };

  const handleEditChange = (columnId, value) => {
    setEditedData((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 

  let values = {
    id:"",
    userName : "",
    phoneNum : "", 
    userType : "",
    appType : ""    
  };   
  
  const columns = React.useMemo(
    () => [
      // {
      //   Header: t("ID"),
      //   accessor: "id",
      // },
      // {
      //   Header: t("Created Date"),
      //   accessor: "createdDate",
      // },      
      {
        Header: t("Updated Date"),
        accessor: "updatedDate",
      },
      // {
      //   Header: t("Created By"),
      //   accessor: "createdBy",
      // },    
      {
        Header: t("Updated By"),
        accessor: "updateBy",
      },   
      {
        Header: t("User Name"),
        accessor: "userName",
        Cell: (cell) => <EditableCell cell={cell} />        
      },  
      // {
      //   Header: t("User Name"),
      //   accessor: "userName",
      //   Cell: ({ row, column, updateData, handleSubmit }) => {
      //     const [userName, setUserName] = useState(row.original.userName);          
      //     const handleChange = (e) => {
      //       setUserName(e.target.value);
      //     };
  
      //     const handleSave = () => {
      //       console.log("Modified Value:", userName);
  
      //       // Ensure the updateData function is being called properly
      //       if (updateData) {
      //         // Update data only if the value has changed
      //         if (userName !== row.original.userName) {
      //           updateData(row.index, { ...row.original, userName });
                
      //           // After updating, trigger handleSubmit with the updated data
      //           if (handleSubmit) {
      //             handleSubmit({ ...row.original, userName });
      //           } else {
      //             console.error("handleSubmit is not defined");
      //           }
      //         }
      //       } else {
      //         console.error("updateData is not defined");
      //       }
  
      //       // Assuming keyValue is the state you want to update with the new userName
      //       if (keyValue !== undefined) {
      //         setKeyValue(prevKeyValue => {
      //           const updatedKeyValue = prevKeyValue.map(item => 
      //             item.id === row.original.id
      //               ? { ...item, userName }
      //               : item
      //           );  
      //           return updatedKeyValue;
      //         });
      //       } else {
      //         console.error("keyValue state is not defined");
      //       }
      //     };
  
      //     return (
      //       <div>
      //         <input
      //           type="text"
      //           value={userName}  // Changed to value instead of userName for controlled component
      //           onChange={handleChange}
      //           onBlur={handleSave} // Save on blur (focus out)
      //           onKeyDown={(e) => { // Optional: Save on Enter id press
      //             if (e.id === "Enter") {
      //               handleSave();
      //             }
      //           }}
      //         />
      //         {/* <button onClick={handleSave}>Save</button> Added Save Button */}
      //       </div>
      //     );
      //   }
      // }, 
      {
        Header: t("Phone Number"),
        accessor: "phoneNum",
        Cell: (cell) => <EditableCell cell={cell} />
      },            
      // {
      //   Header: t("Phone Number"),
      //   accessor: "phoneNum",
      //   Cell: ({ row, column, updateData, handleSubmit }) => {
      //     const [phoneNum, setPhoneNum] = useState(row.original.phoneNum);
      
      //     const handleChange = (e) => {
      //       setPhoneNum(e.target.value);
      //     };
      
      //     const handleSave = () => {
      //       console.log("Modified Value:", phoneNum);
      
      //       if (updateData) {
      //         if (phoneNum !== row.original.phoneNum) {
      //           updateData(row.index, { ...row.original, phoneNum });
      
      //           if (handleSubmit) {
      //             handleSubmit({ ...row.original, phoneNum });
      //           } else {
      //             console.error("handleSubmit is not defined");
      //           }
      //         }
      //       } else {
      //         console.error("updateData is not defined");
      //       }
      
      //       if (keyValue !== undefined) {
      //         setKeyValue((prevKeyValue) => {
      //           const updatedKeyValue = prevKeyValue.map((item) =>
      //             item.id === row.original.id
      //               ? { ...item, phoneNum }
      //               : item
      //           );
      //           return updatedKeyValue;
      //         });
      //       } else {
      //         console.error("keyValue state is not defined");
      //       }
      //     };
      
      //     return (
      //       <div>
      //         <input
      //           type="text"
      //           value={phoneNum}
      //           onChange={handleChange}
      //           onBlur={handleSave}
      //           onKeyDown={(e) => {
      //             if (e.id === "Enter") {
      //               handleSave();
      //             }
      //           }}
      //         />
      //       </div>
      //     );
      //   }
      // },   
      // {
      //   Header: t("User Type"),
      //   accessor: "userType",
      // }, 
      {
        Header: t("App Type"),
        accessor: "appType",
      },      
      // {
        
      //   Header: "Edit",
      //   Cell: ({ row }) => {
      //   const handleClick = () => { 

      //   const alphaId = row.original.id;
      //   const userName = row.original.userName;
      //   const phoneNum = row.original.phoneNum;
      //   const userType = row.original.userType;
      //   const appType = row.original.appType;
      
      //   setSelectedObjId(alphaId);
      //   setAlphaUserName(userName);
      //   setAlphaPhoneNum(phoneNum);
      //   setAlphaUserType(userType);
      //   setAlphaAppType(appType);
        
      //   console.log("alphaId", alphaId);      

      //   console.log("selectedObjId", selectedObjId);
      //   console.log("alphaUserName", alphaUserName);
      //   console.log("alphaPhoneNum", alphaPhoneNum);
      //   console.log("alphaUserType", alphaUserType);
      //   console.log("alphaAppType", alphaAppType);

      // }; 

      // return (
      // <IconButton onClick={handleClick}>
      //   <i className="fa fa-edit" aria-hidden="true"></i>
      // </IconButton>
      // );
      // }
      // },
      {
        Header: "Edit",
        Cell: ({ row }) =>
          editingRowId === row.original.id ? (
            <>
              <IconButton onClick={handleSaveEdit}>
                <i className="fa fa-save"></i>
              </IconButton>
              <IconButton onClick={() => setEditingRowId(null)}>
                <i className="fa fa-times"></i>
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => handleEditClick(row)}>
              <i className="fa fa-edit"></i>
            </IconButton>
          ),          
      },
      
    ],
    [editingRowId, editedData, focusedCell]    
  );
  const handleCellClick = (rowId, columnId) => {
    setFocusedCell(`${rowId}-${columnId}`);
  };

  const EditableCell = ({ cell }) => {
    const { row, column } = cell; 
    const isEditing = editingRowId === row.original.id && focusedCell === `${row.original.id}-${column.id}`;
    const cellKey = `${row.original.id}-${column.id}`;

        // const alphaId = row.original.id;
        // const userName = row.original.userName;
        // const phoneNum = row.original.phoneNum;
        const userType = row.original.userType;
        const appType = row.original.appType;
      
        setSelectedObjId(editingRowId);
        // setAlphaUserName(userName);
        // setAlphaPhoneNum(phoneNum);
        setAlphaUserType(userType);
        setAlphaAppType(appType);
  
    return (
      <span 
      onClick={() => handleCellClick(row.original.id, column.id)} 
      style={{ display: "inline-block", minWidth: "100px" }}
      >
      {isEditing ? (
        <TextField
          value={editedData[column.id] || ""}
          //onChange={(e) => handleEditChange(column.id, e.target.value)}      
          onChange={(e) => {
            
            // if (column.id === 'userName') {
            //     handleEditChange('userName', e.target.value);
            //     const userName = ('userName', e.target.value);
            //     setAlphaUserName(userName);                
            // } else if (column.id === 'phoneNum') {
            //     handleEditChange('phoneNum', e.target.value);
            //     const phoneNum = ('phoneNum', e.target.value);
            //     setAlphaPhoneNum(phoneNum);
            // } else {
            //     handleEditChange(column.id, e.target.value);
            // }

            if (column.id === 'userName' || column.id === 'phoneNum') {
              handleEditChange(column.id, e.target.value);
              column.id === 'userName' ? setAlphaUserName(e.target.value) : setAlphaPhoneNum(e.target.value);
          } else {
              handleEditChange(column.id, e.target.value);
          }

        }}    
          variant="outlined" 
          size="small"
          autoFocus 
        />
      ) : (
        row.original[column.id]        
      )}
    </span>
    
    );
  };

  // const handleSaveEdit = async () => {
  //   try {
  //     await provisioningService.updatePaymentDetails(editedData);
  
  //     setPassbookData((prevData) =>
  //       prevData.map((item) =>
  //         item.id === editedData.id ? { ...item, ...editedData } : item
  //       )
  //     );
  
  //     setEditingRowId(null);
  //     setEditedData({});
  //     setFocusedCell(null); 
  //   } catch (error) {
  //     console.error("Error updating data:", error);
  //   }
  // };

  

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getAlphaUserList();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, [reloaddd]);

  const data = React.useMemo(() => [...keyValue], [keyValue]);

   function getAlphaUserList() {
      setStatus("loading");
      apkServices.getAlphaList().then((data) => {
        console.log("apkUploadList", data);
        setKeyValue(data || []);
        setPageInfo(data.pageInfo);      
        setStatus("success");      
      })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
          setStatus("failed");
        });
    }

    const dropitems = [
      {
        item: (
          <Link to="/apk/alpha_create">
           {t("alpha_create_Label")}
          </Link>
        )
      }
    ];

    async function handleSubmit(values, resetForm, setSubmitting) {
        console.log("createPage",values);

        // let payLoad = { "id": values.keyValue,
        //                 "userName":values.keyValue, 
        //                 "phoneNum":values.keyValue,
        //                 "userType":values.keyValue,
        //                 "appType":values.keyValue
        //               }  
        
        // let payLoad = {
        //   "id": keyValue.map(item => item.id),  // or replace 'id' with any other field you want
        //   "userName": keyValue.map(item => item.userName),
        //   "phoneNum": keyValue.map(item => item.phoneNum),
        //   "userType": keyValue.map(item => item.userType),
        //   "appType": keyValue.map(item => item.appType)
        // };     

        let tempObj = values.keyValue.find(item => item.id === selectedObjId) || {};   
        tempObj.userName = alphaUserName;   
        tempObj.phoneNum = alphaPhoneNum;

        console.log("alphaUserName", alphaUserName);
        console.log("tempObj", tempObj);
        console.log("selectedObjId",selectedObjId);

        let payLoad = {
          id: tempObj.id,
          userName: tempObj.userName,
          phoneNum: tempObj.phoneNum,
          userType: tempObj.userType,
          appType: tempObj.appType
        };

        console.log("alpha user dtls",payLoad);
    
        try {
          const repeat = null;
          const result = await apkServices.editAlpha(payLoad);
          console.log("Result:============= ", result);
          if(result.data === null && result.message && result.status === "failure"){        
            // setCreateFailure(true);
            //setFailureMsg(result.message);
            // setSubmitting(false);
          }else{
    
            try {
              resetForm();
              setCreateSuccess(true);
              //resetForm();      
              //redirect to list page
              setTimeout(() => {
               //history.push("/configuration/pbp_list");
                history.push("/apk/alpha_list");
              }, 3000); 
              
              setEditingRowId(null);
              setEditedData({});
              setFocusedCell(null);
              
            } catch (e) {
              //console.error(e);
              // setCreateFailure(true);
              // setFailureMsg(e.error.message);
              // setSubmitting(false);
            }
    
          }
        } catch (e) {
          console.error(e);
        }        
      }

      const handleSaveEdit = async () => {
        setStatus("loading");
         // Validate phoneNum
        const phoneNumPattern = /^[0-9]{10}$/;        
        if (!phoneNumPattern.test(editedData.phoneNum)) {
          setStatus("error");
          setCreateFailure(true);
          //alert("Please enter a valid phone number with exactly 10 digits.");
          return; // Stop further execution if validation fails
        }
        let payLoad = {
          id: editedData.id,
          userName: editedData.userName,
          phoneNum: editedData.phoneNum,
          userType: editedData.userType,
          appType: editedData.appType
        };

        try {
          const repeat = null;
          const result = await apkServices.editAlpha(payLoad);
          console.log("Result:============= ", result);
          if(result.data === null && result.message && result.status === "failure"){       
            // setCreateFailure(true);
               setFailureMsg(result.message);
            // setSubmitting(false);
          }else{
    
            try {
              console.log("inside try");
              // resetForm();
              setCreateSuccess(true);
              setReloaddd(!reloaddd);
              // setKeyValue(data || []);
              // setPageInfo(data.pageInfo);
              // setStatus("success"); 
              //resetForm();      
              //redirect to list page
              // setTimeout(() => {
              //  //history.push("/configuration/pbp_list");
              //   history.push("/apk/alpha_list");
              // }, 3000); 
              
              setEditingRowId(null);
              setEditedData({});
              setFocusedCell(null);
              // window.location.reload();
              
            } catch (e) {
              console.log("inside catch");
              //console.error(e);
              // setCreateFailure(true);
              // setFailureMsg(e.error.message);
              // setSubmitting(false);
            }
    
          }
        } catch (e) {
          console.error(e);
        }
      };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Alpha User List"//{t("pbp_HeaderList_Label")}
         /* breadcrumbs={[
            { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("retailer_Breadcrumb_Config_Label") },
            { label: t("retailer_Breadcrumb_Retailer_Label") }
          ]} */        
        />
      </Grid>   

      {createSuccess ? 
              <SweetAlert success
                title="Alpha User Modified Successfully"
                onConfirm={hideAlert}
                //disabled={ disableOkBtn ? true : false }
                onCancel={hideAlert}
                timeout={2000}
              ></SweetAlert> 
            : null}
      
            {createFailure ? 
              <SweetAlert danger
                title={"Please enter a valid phone number with exactly 10 digits."}
                onConfirm={hideAlert}
                //disabled={ disableOkBtn ? true : false }
                onCancel={hideAlert}               
                timeout={3000}
              ></SweetAlert> 
            : null}   

      <Paper
         elevation={2}
         style={{
         padding: "2em",
         width: "100%"
        }}
      > 
      <Grid item>     
          {/* <CommonForm
            //fields={fields}              
            submitLabel={t("site_Form_Submit_Label")}
            submittingLabel={t("site_Form_Submitting_Label")}
            initialValues={values}              
            //validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}                           
            enableReinitialize
            validateOnChange={true} 

            onSubmit={(values, { resetForm, setSubmitting }) => {
            console.log('onSubmit',values.isActive, values);
            const updatedValues = { ...values, keyValue };  
            handleSubmit(updatedValues, resetForm, setSubmitting);
            }}
            formikRef={formikForm}             
            buttonSize="3"
            buttonPosition="right"  
          />     */}
          </Grid>   
      { status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >        
         <CircularProgress />
        </Grid>
      ) : (        
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}
            hidePagination={true}
          />          
        )}
      </Paper>
      
    </Grid>
  );
};

export default AlphaList;
