
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import * as Yup from "yup";
import BrowseButtonApkFile from "components/BrowseButtonApkFile";

export default function AlphaForm({  
values,
page,
handleSubmit

}) {

  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);  
  const [toggle,setToggle] = React.useState(false);

  const handleToggle = (event) => {
    setToggle(!toggle)
    values[event.target.name] = !values[event.target.name]
    console.log("Toggles: ", values[event.target.name]);
    console.log("Toggles: ", event.target.name);
  }  

const UserType = [
  { name: "GENERIC", value: "GENERIC" },
  { name: "ALPHA", value: "ALPHA" }];

const ApkPackage = [
  { name: "stellapps.farmerapp", value: "stellapps.farmerapp" },
  { name: "stellapps.farmerapp.dev", value: "stellapps.farmerapp.dev" }];

let validations = {
  userName: Yup.string().required(t("alpha_Form_Validation_userName")),  
  // phoneNum: Yup.string().required(t("alpha_Form_Validation_phoneNum"))                        
  //     .matches(/^[0-9]+$/, "Must be only digits"),
  phoneNum: Yup.string()
  .required(t("alpha_Form_Validation_phoneNum"))
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Phone number must be exactly 10 digits")
  .max(10, "Phone number must be exactly 10 digits"),   
  //userType: Yup.string().required(t("alpha_Form_Validation_userType")),
  //appType: Yup.string().required(t("alpha_Form_Validation_appType")) 
  };  

  const fields = [   
    {
      type: "textMandatory",
      name: "userName",
      label: t("alpha_Form_userName_label"),
      placeholder: t("alpha_Form_userName_label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "textMandatory",
      name: "phoneNum",
      label: t("alpha_Form_phoneNum_label"),
      placeholder: t("alpha_Form_phoneNum_label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },

    // {
    //   type: "select",
    //   name: "userType",      
    //   label: t("apk_Form_releaseType_Label"),
    //   placeholder: t("apk_Form_releaseType_Label"),
    //   option: 
    //   UserType.length > 0
    //     ? UserType.map((uType) => ({
    //         value: uType.value,  // Use 'value' from the userType array
    //         label: uType.name    // Use 'name' from the userType array
    //       }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },

    // {
    //   type: "text",
    //   name: "userType",      
    //   label: t("alpha_Form_userType_Label"),
    //   //placeholder: "ALPHA",
    //   value: "ALPHA",
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },

    {
      type: "text",
      name: "appType",
      label: t("alpha_Form_appType_label"),
      placeholder: "smartfarms", //t("apk_Form_appType_Label"),
      value: "smartfarms",  // Set default value to SMART_FARMS
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },      
    },   

 
  // {
  //   type: "custom",
  //   component: ({ values, setFieldValue}, renderFields) => (
  //     <BrowseButtonApkFile
  //       //handleMyClick={handleMyClick}
  //       //languageValues={language}
  //       setFieldValue={setFieldValue}
  //       values={values} 
            
  //       renderFields={renderFields}
  //     />
  //   )
  // }  

  ];

  console.log("values####",values);
  
  return (
  <Grid container>
    <Grid item xs={12}>
      <PageHeader
        title="Alpha User"
        // breadcrumbs={[
        //   { label: "Dashboard", link: "/dashboard" },
        //   { label: "Configuration" },
        //   { label: "Article" }
        // ]}
      />
    </Grid>

    <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      > 

      <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}              
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}              
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}                           
              enableReinitialize
              validateOnChange={true}
              onSubmit={(values, { resetForm, setSubmitting }) => {              
              handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}             
              buttonSize="3"
              buttonPosition="right"                       
            />            
          }
        </Grid>
     </Paper>
  </Grid> // Grid container closed here
  );
}
