import React from "react";
import { Formik, Field, getIn } from "formik";
import isEmpty from "lodash/isEmpty";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
//import Slider from "@material-ui/core/Slider";
import { TextField } from "formik-material-ui";
import { withTranslation } from "react-i18next";
import "./form.scss";
import CustomDatePicker from "../elements/DatePicker";
import CustomTimePicker from "../elements/TimePicker";
import MultiSelect from "../elements/MultiSelect";
import MultiSelectAll from "../elements/MultiSelectAll";
import MultiObjectSelectAll from "../elements/MultiObjectSelectAll";
import SearchSingleSelect from "../elements/SingleSelect";
import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";
import UserPasswordChangeFields from "./UserPasswordChangeFields";
import TextInputComponent from "../../TextInputComponent";
import FileComponent from "../../FileComponent";
import FormCompletedPercentage from "./FormCompletedPercentage";
import SearchMultiSelect from "./SearchMultiSelect";
import TaskFields from "./TaskFields";
import Checkbox from "@material-ui/core/Checkbox";
import {Radio,RadioGroup} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

function CommonForm({
  onSubmit = () => { },
  validationSchema = () => { },
  initialValues = {},
  validateOnBlur = true,
  validateOnChange = true,
  fields = [],
  submitLabel = "Submit",
  showSubmitButton = true,
  submittingLabel = "Submit",
  enableReinitialize = false,
  showOrgSubmitButton = false,
  ...props
}) {
  const defaultMetaProps = {
    margin: "dense",
    variant: "outlined",
    fullWidth: true
  };

  const defaultGridProps = {
    xs: 12
  };

  const renderFields = (formProps, options = { nested: false }) => field => {
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      handleBlur,
      handleChange
      /* and other goodies */
    } = formProps;

    const gridProps = !!field && {
      ...defaultGridProps,
      ...field.grid
    };
    const metaProps = !!field && {
      ...defaultMetaProps,
      ...field.meta
    };

    return !!field ? (
      <Grid key={field.name} item {...gridProps}>
        {field.type === "completedPercentage" && (
          <FormCompletedPercentage
            formikRef={field.formikRef}
            title={!!field.title ? field.title : ""}
          />
        )}
        {field.type === "email" && (
          <Field
            type="email"
            name={field.name}
            label={field.label}
            component={TextField}
            placeholder={field.placeholder || ""}
            InputLabelProps={{ shrink: true }}
            {...metaProps}
          />
          // eslint and prettier
        )}
        {(field.type === "text" || field.type === "number") && (
          <Field
            type={field.type || "text"}
            name={field.name}
            disabled={field.disabled}
            label={field.label}
            autoComplete={field.autoComplete || false}
            component={TextField}
            placeholder={field.placeholder || ""}
            InputLabelProps={{ shrink: true }}
            inputProps={
              !!field.customOnChange
                ? {
                  onChange: async e => {
                    setFieldTouched(field.name, true, false);
                    setFieldValue(field.name, e.target.value);
                    await field.customOnChange(e.target.value);
                  },
                  value: getIn(values, field.name),
                  shrink: true
                }
                : {}
            }
            {...metaProps}
          />
        )}
        {/* ================================================================================= */}
        {(field.type === "fixedText") && (
          <Field
            required={field.required}
            type= "text"
            multiline
            rowsMax={5}
            name={field.name}
            inputProps={{ value : field.valuee, readOnly : true }}
            disabled={field.disabled}
            label={field.label}
            component={TextField}
            placeholder={field.placeholder || ""}
            {...metaProps}
          />
        )}
        {/* ====================================================================================== */}
        {(field.type === "textMandatory" || field.type === "numberMandatory") && (
          <Field
            type={field.type || "text"}
            name={field.name}
            disabled={field.disabled}
            label={field.label}
            autoComplete={field.autoComplete || false}
            component={TextField}
            placeholder={field.placeholder || ""}
            InputLabelProps={{ shrink: true, required: true }}
            inputProps={
              !!field.customOnChange
                ? {
                  onChange: async e => {
                    setFieldTouched(field.name, true, false);
                    setFieldValue(field.name, e.target.value);
                    await field.customOnChange(e.target.value);
                  },
                  value: getIn(values, field.name),
                  shrink: true
                }
                : {}
            }
            {...metaProps}
          />
        )}
        {field.type === "url" && (
          <Field
            type={field.type || "url"}
            name={field.name}
            disabled={field.disabled}
            label={field.label}
            autoComplete={field.autoComplete || false}
            component={TextField}
            placeholder={"https://example.com"}
            InputLabelProps={{ shrink: true }}
            pattern={
              ("^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
                "i")
            }
            size="30"
            {...metaProps}
          />
        )}

        {field.type === "textarea" && (
          <Field
            name={field.name}
            component={TextInputComponent}
            disabled={field.disabled}
            label={field.label}
            errorMessage={getIn(errors, field.name)}
            // touched={getIn(errors, field.name)}
            {...metaProps}
          />
        )}
        {field.type === "files" && (
          <Field
            name={field.name}
            accept={field.accept}
            supported_formats={field.supported_formats}
            component={FileComponent}
            title="Select a file"
            setFieldValue={setFieldValue}
            errorMessage={getIn(errors, field.name)}
            touched={getIn(errors, field.name)}
            style={{ display: "flex" }}
            onBlur={handleBlur}
          />
        )}
        {field.type === "datePicker" && (
          <CustomDatePicker
            name={field.name}
            label={field.label}
            value={getIn(values, field.name)}
            handleDateChange={date => field.customOnChange ?
              field.customOnChange(date, setFieldValue, values)
              :
              setFieldValue(field.name, date)
            }
            setFieldTouched={setFieldTouched}
            maxDate={field.maxDate}
            minDate={field.minDate}
            error={getIn(errors, field.name)}
            touched={touched}
            readOnly={true}
            editable={false}
            disabled={field.disabled}
          />
        )}
        {field.type === "timePicker" && (
          <CustomTimePicker
            name={field.name}
            label={field.label}
            value={getIn(values, field.name)}
            handleTimeChange={time => setFieldValue(field.name, time)}
            setFieldTouched={setFieldTouched}
            error={getIn(errors, field.name)}
            touched={touched}
          />
        )}

        {field.type === "monthPicker" && (
          <CustomDatePicker
            name={field.name}
            label={field.label}
            value={getIn(values, field.name)}
            handleDateChange={date => setFieldValue(field.name, date)}
            setFieldTouched={setFieldTouched}
            maxDate={field.maxDate}
            minDate={field.minDate}
            error={getIn(errors, field.name)}
            touched={touched}
            openTo="month"
            views="month"
            format="MMMM yyyy"
          />
        )}

        {field.type === "select" && (
          <Field
            name={field.name}
            id={field.name}
            label={field.label}
            disabled={field.disabled}
            component={TextField}
            select
            placeholder={field.placeholder || field.label || ""}
            inputProps={
              !!field.customOnChange
                ? {
                  onChange: async e => {
                    setFieldTouched(field.name, true, false);
                    setFieldValue(field.name, e.target.value);
                    await field.customOnChange(e.target.value);
                  },
                  value: getIn(values, field.name),
                  shrink: true
                }
                : {}
            }
            {...metaProps}
          >
            <MenuItem disabled value="none">
              {field.label}
            </MenuItem>
            {field.option.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        )}

        {field.type === "selectMandatory" && (
          <Field
            name={field.name}
            id={field.name}
            label={field.label}
            disabled={field.disabled}
            component={TextField}
            select
            placeholder={field.placeholder || field.label || ""}
            InputLabelProps={{ shrink: true, required: true }}
            inputProps={
              !!field.customOnChange
                ? {
                  onChange: async e => {
                    setFieldTouched(field.name, true, false);
                    setFieldValue(field.name, e.target.value);
                    await field.customOnChange(e.target.value);
                  },
                  value: getIn(values, field.name),
                  shrink: true
                }
                : {}
            }
            {...metaProps}
          >
            <MenuItem disabled value="none">
              {field.label}
            </MenuItem>
            {field.option.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        )}

        {field.type === "radiogroup" && (
          <><p>{field.label}</p>
            <RadioGroup onChange={field.customOnChange} row>
              {field.option.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label} />
              ))}
            </RadioGroup></>





          //   <Field
          //     name={field.name}
          //     id={field.name}
          //     label={field.label}
          //     disabled={field.disabled}
          //     component={RadioGroup}
          //     // type="radio"
          //     placeholder={field.placeholder || field.label || ""}
          //     inputProps={
          //       !!field.customOnChange
          //         ? {
          //           onChange: async e => {
          //             setFieldTouched(field.name, true, false);
          //             console.log("e.target.value",e.target.value);
          //             setFieldValue(field.name, e.target.value);
          //             await field.customOnChange(e.target.value);
          //           },
          //           value: getIn(values, field.name),
          //           shrink: true
          //         }
          //         : {}
          //     }
          //     {...metaProps}
          //   >
          //   {field.option.map((option) => (
          //     <FormControlLabel
          //       key={option.value}
          //       value={option.value}
          //       control={<Radio />}
          //       label={option.label}
          //     />
          //   ))}
          // </Field>
          // }

          // />
          // </RadioGroup>
          // </FormControl>
        )}


        {field.type === "userPasswordChange" && (
          <UserPasswordChangeFields
            user={getIn(values, field.userField)}
            setFieldValue={setFieldValue}
            passwordField={field.passwordField}
            confirmPasswordField={field.confirmPasswordField}
            values={values}
            metaProps={metaProps}
          />
        )}
        {field.type === "multiSelect" && (
          <MultiSelect
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            name={field.name}
            field={field.name}
            options={field.option}
            label={field.label}
            loading={field.loading}
            handleChange={setFieldValue}
            onChange={field.customOnChange}
          />
        )}
        {field.type === "multiSelectAll" && (
          <MultiSelectAll
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            name={field.name}
            field={field.name}
            options={field.option}
            label={field.label}
            disabled={field.disabled}
            loading={field.loading}
            handleChange={setFieldValue}
            onChange={field.customOnChange}
            mandatory={field.mandatory}
          />
        )}
        {field.type === "multiObjectSelectAll" && (
          <MultiObjectSelectAll
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            name={field.name}
            mapper={field.mapper}
            field={field.name}
            options={field.option}
            label={field.label}
            disabled={field.disabled}
            loading={field.loading}
            handleChange={setFieldValue}
            onChange={field.customOnChange}
            mandatory={field.mandatory}
          />
        )}
        {field.type === "multiObjectSelectAll" && (
          <MultiObjectSelectAll
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            name={field.name}
            mapper={field.mapper}
            field={field.name}
            options={field.option}
            label={field.label}
            disabled={field.disabled}
            loading={field.loading}
            handleChange={setFieldValue}
            onChange={field.customOnChange}
            mandatory={field.mandatory}
          />
        )}
        {field.type === "multiSelectSearch" && (
          <SearchMultiSelect
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            error={getIn(errors, field.name)}
            name={field.name}
            options={field.option}
            label={field.label}
            customOnChange={field.customOnChange}
          />
        )}
        {field.type === "singleSelectSearch" && (
          <SearchSingleSelect
            value={
              !!getIn(values, field.name) &&
                getIn(values, field.name).length > 0
                ? getIn(values, field.name)
                : []
            }
            name={field.name}
            error={getIn(errors, field.name)}
            options={field.option}
            label={field.label}
            customOnChange={field.customOnChange}
          />
        )}
        {field.type === "label" && (
          <Grid item className={field.className}>
            <FormLabel className="mt-3" name={field.name} component="fieldset">
              {field.label}
            </FormLabel>
          </Grid>
        )}

        {field.type === "checkbox" && (
          <Grid item className={field.className}>
            <Checkbox
              name={field.name}
              checked={field.checked}
              value={getIn(values, field.name)}
              onChange={handleChange}
              error={getIn(errors, field.name)}
              color="primary"
              className={field.className}
            />
          </Grid>
        )}
        {field.type === "password" && (
          <Field
            type={"password"}
            name={field.name}
            label={field.label}
            component={TextField}
            disabled={field.disabled}
            autoComplete={field.autoComplete || false}
            placeholder={field.placeholder || ""}
            value={values[field.name]}
            error={errors[field.name]}
            {...metaProps}
          />
        )}
        {field.type === "passwordMandatory" && (
          <Field
            type={"password"}
            name={field.name}
            label={field.label}
            component={TextField}
            disabled={field.disabled}
            autoComplete={field.autoComplete || false}
            placeholder={field.placeholder || ""}
            InputLabelProps={{ shrink: true, required: true }}
            value={values[field.name]}
            error={errors[field.name]}
            {...metaProps}
          />
        )}
        {field.type === "hr" && <hr />}
        {field.type === "header" && <h3>{field.name}</h3>}
        {field.type === "customHeader" && <h6>{field.name}</h6>}
        {field.type === "custom" && field.component(formProps, renderFields)}
        {field.type === "TaskFields" && (
          <TaskFields
            name={field.name}
            values={getIn(values, field.name)}
            TaskValuesTypes={field.TaskValuesTypes}
            errors={errors[field.name]}
          />
        )}
      </Grid>
    ) : null;
  };

  return (
    <div className="common-form">
      <div className="data-form">
        <Formik
          initialValues={initialValues}
          validateOnBlur={validateOnBlur}
          validateOnChange={validateOnChange}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          ref={props.formikRef}
          enableReinitialize={!!enableReinitialize}
        >
          {formProps => {
            const {
              errors,
              handleSubmit,
              isSubmitting,
              submitForm
            } = formProps;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {fields && fields.map(renderFields(formProps))}

                  {!!props.formFooter && <>{props.formFooter}</>}

                  {showSubmitButton && (
                    <CustomizedPrimaryButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() => submitForm()}
                      className={`${isSubmitting ? "inactive-button" : ""} `}
                      disabled={isSubmitting}
                      label={!isSubmitting ? submitLabel : submittingLabel}
                      position={props.buttonPosition}
                      width={props.buttonSize}
                      inlineButton={props.inlineButton}
                    ></CustomizedPrimaryButton>
                  )}

                  {showOrgSubmitButton && (
                    <button
                      type="button"
                      onClick={() => submitForm()}
                      className="add-icon"
                      color="primary"
                      variant="contained"
                      size="medium"
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </button>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default withTranslation("common")(CommonForm);
