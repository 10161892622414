import { func } from "prop-types";
import { utilityServices } from "./utilityServices";
import { language } from "i18next";

function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
}

async function getApkUploadList(aType, rType, vName){ 
  
  // let url;
  // if (aType ==="", rType ==="", vName === "") {
  //   url = process.env.REACT_APP_APK_UPLOAD_LIST;
  // } else {
  //   url = `${process.env.REACT_APP_APK_UPLOAD_LIST}?appType=${aType}&releaseType=${rType}&versionName=${vName}`;
  // }

  let url = process.env.REACT_APP_APK_UPLOAD_LIST;

  if (aType.trim() !== "" || rType.trim() !== "" || vName.trim() !== "") {
      const queryParams = [];
  
    if (aType.trim() !== "") queryParams.push(`appType=${aType.trim()}`);
    if (rType.trim() !== "") queryParams.push(`releaseType=${rType.trim()}`);
    if (vName.trim() !== "") queryParams.push(`versionName=${vName.trim()}`);
  
    if (queryParams.length > 0) {
      url = `${url}?${queryParams.join("&")}`;
    }
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),      
      //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
      //fetchTag: (fetchtag) // Passing dropdownfield
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function ApkUpdate(values) {
  
  // console.log(values);
  // Creating the form data object to hold the parameters
  const formData = new FormData();
  formData.append('file', values.file);
  formData.append('appType', values.appType);
  formData.append('versionName', values.versionName);
  formData.append('apkPackage', values.apkPackage);
  formData.append('versionCode', values.versionCode);
  formData.append('releaseType', values.releaseType);
  // formData.forEach((value, key) => {
  //   console.log(key + ": " + value);
  // });

  const url = process.env.REACT_APP_APK_UPLOAD_CREATE;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
    },
    body: formData,
  };

    try {
      const response = await fetch(url, requestOptions);
      // const responseData = await response.json();
       console.log("responseData",response)
      if (!response.ok) {
        // In case the response is 200 but still has an error message
        throw new Error(response.message || 'Unknown error');
      }      
      return response;  // Return the successful response

      } catch (error) {
          console.error("API error:", error);
          return {
              success: false,
              message: error.message || 'Something went wrong with the request.',
          };
      }  
  } 

  async function getAlphaList(){  
    //const url = process.env.REACT_APP_APK_USER_LIST;  
    const url = `${process.env.REACT_APP_APK_USER_LIST}?userType=ALPHA`;
    const requestOptions = {
      method: "GET",
      headers: {
       // "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),      
        //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
        Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
        //fetchTag: (fetchtag) // Passing dropdownfield
      },
      body: JSON.stringify()
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  async function AlphaUserCreate(values){
    const url = process.env.REACT_APP_APK_USER_CREATE
    const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
  }

  async function getApkPackages(){  
    //const url = process.env.REACT_APP_APK_USER_LIST;  
    const url = `${process.env.REACT_APP_APK_GET_PACKAGES}`;  //  ?appType=SMART_FARMS
    const requestOptions = {
      method: "GET",
      headers: {
       // "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),      
        //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
        Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
        //fetchTag: (fetchtag) // Passing dropdownfield
      },
      //body: JSON.stringify()
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  async function getApkPackageName(apptype){  
    //const url = process.env.REACT_APP_APK_USER_LIST;  
    //const url = `${process.env.REACT_APP_APK_GET_PACKAGES}`;  //  ?appType=SMART_FARMS
    const url = `${process.env.REACT_APP_APK_GET_PACKAGES}?appType=${apptype}`;
    const requestOptions = {
      method: "GET",
      headers: {
       // "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),      
        //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
        Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
        //fetchTag: (fetchtag) // Passing dropdownfield
      },
      //body: JSON.stringify()
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  async function editAlpha(alpha) {
    const url = `${process.env.REACT_APP_ALPHA_USER_EDIT}`;                
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),
        //"Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
        Authorization:`Bearer ${localStorage.getItem("bearerToken")}` 
      },
      body: JSON.stringify(alpha)
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

export const apkServices = {   
  getApkUploadList,  
  ApkUpdate,
  getAlphaList,
  AlphaUserCreate,
  getApkPackages,
  getApkPackageName,
  editAlpha
};
